
code { 
	background: none !important;
	border: 0 !important;
}
$sg-color: gray;

ul.sg-nav {
	list-style: none; 
}
.sg-heading {
	margin-top: 3rem;
}
h2.sg-section-header, h1.sg-header {
	margin-top: 3rem;
	color: $sg-color;
}
.sg-color-swatch {
	width: 6rem;
	height: 6rem;
	border-radius: 1000000px;
	background: $primary-color;
	margin: 0 auto;
	margin-bottom: 1rem;
}
.sg-color-swatch.anchor-swatch {
	background: $secondary-color;
}
.sg-color-swatch.base-swatch {
	background: $gray;
}
.sg-grid {
	background: gray;
	height: 140px;
	font-size: 2rem;
	text-align: center;
}
.sg-grid-content {
	background: $gray;
	padding-top: 40px;
	height: 140px;
}
.sg-icon {
	padding: 2rem 3rem 0 3rem;
}
a.to-top {
	margin-top: 3rem;
	font-size: 0.9rem;
	display: block;
}
.icon-sg {
	color: $primary-color;
}