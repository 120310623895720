// NAVIGATION

// Top bar

nav#top-bar {
	
	padding: 30px 0;

	@include media($large-up) {
		padding: 60px 0 45px;
	}

	section.container {
		padding-top:0;
		padding-bottom:0;
	}
}

#logo-area {
	
	@include media($large-up) {
		@include span-columns(3);
	}

	#ocy-logo {
		width: 130px;
		height: 30px;
		display: block;
		overflow: hidden;
		z-index: 999;
		position: relative;
		transition: none;

		@include media($large-up) {
			width: 216px;
			height: 50px;
		}
	}

	a, a:active, a:visited, a:hover {
		border-bottom: 0;
	}

}

#js-menu-button {

	width: 30px;
	height: 21px;
	display: block;
	top: 34px;
	right:30px;
	position: absolute;
	cursor: pointer;
	z-index: 999;
	transition: none;

	@include media($large-up) {
		display: none;
		top: 45px;
		right: 45px;
	}

	span {
		width: 100%;
		height: 1px;
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		overflow: hidden;
		background-color: $black;
		text-indent: -9000px;
		transition: all .15s ease-in-out;
	}

	&:before, &:after {
		content:"";
		width: 100%;
		height: 1px;
		background-color: $black;
		position: absolute;
		left:0;
		transition: all .15s ease-in-out;
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}
	
	&.active {

		span {
			left: 50%;
			width: 0%;
		}

		&:before {
			transform: translateX(0px) translateY(10px) rotate(45deg);
		}

		&:after {
			transform: translateX(0px) translateY(-10px) rotate(-45deg);
		}
		
	}
}

ul#navigation {
	list-style: none;
	visibility: hidden;
	text-align: left;
	margin:0;
	padding:0;
	opacity: 0;
	display: block;

	@media only screen and (max-width: 1023px) {
		width: 100%;
		height: 100%;
		height: 100vh;
		position: absolute;
		top: -100%;
		left: 0;
		background-color: $white;
		padding: 90px 45px 45px 45px;
		z-index: 998;
		transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		
		li {
			transform: translateX(-30%); 
			transition: all .35s cubic-bezier(.77,0,.175,1);
			opacity: 0;
			-webkit-order: 0;
			-ms-flex-order: 0;
			order: 0;
			-webkit-flex: 0 1 auto;
			-ms-flex: 0 1 auto;
			flex: 0 1 auto;
			-webkit-align-self: center;
			-ms-flex-item-align: center;
			align-self: center;
			margin: 5px 0;
			padding: 0;
				
			&:nth-child(1) {
				transition-delay: .1s;
			}

			&:nth-child(2) {
				transition-delay: .15s;
			}

			&:nth-child(3) {
				transition-delay: .2s;
			}

			&:nth-child(3) {
				transition-delay: .25s;
			}

			&:nth-child(4) {
				transition-delay: .3s;
			}

			&:nth-child(5) {
				transition-delay: .35s;
			}

			&:nth-child(6) {
				transition-delay: .4s;
			}

			&:nth-child(7) {
				transition-delay: .45s;
			}

			&:nth-child(8) {
				transition-delay: .5s;
			}
		}

		&.active {
			visibility: visible;
			opacity: 1;
			top: 0;

			li {
				transform: translateX(0);
				opacity: 1;
			}
		}
	}

	@include media($medium-up) {
		padding: 120px 45px 45px;
	}

	@include media($large-up) {
		@include span-columns(9);
		text-align: right;
		visibility: visible;
		opacity: 1;
		padding:0;
	}

	li {
		width: 100%;
		display: inline-block;

		@include media($large-up) {
			border: 0;
			width: auto;
			margin: 0;
			height: 50px;
			line-height: 50px;
			padding-left: 35px;
		}

		a {
			border: none;
			font-weight: 400;
			font-size: 1.25rem;
			color: $primary-color;

			@include media($medium-up) {
				font-size: 1.375rem;
			}

			@include media($large-up) {
				font-size: 1.125rem;
			}

			@media only screen and (min-width: 1440px) {
				font-size: 1.25rem;
			}

			&:hover {
				color: $link-active-color;
			}
			&.active{
				font-weight:600;
				color: $link-underline-color;
			}
		}

	}
}

// Sub nav

.container.nav {
	padding-top:0;
	padding-bottom:30px;
	margin-top:0;
	
	@include media($large-up) {
		margin-bottom: 30px;
		margin-top:-15px;

		@media only screen and (min-width: 1441px) {
			margin-bottom: 45px;
		}
	}
}

ul#sub-nav {
	
	list-style: none;
	padding-left: 0;
	margin: 0;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	padding-top: 20px;
	padding-bottom: 20px;

	@include media($medium-up) {
		columns: 2;
	}

	@include media($large-up) {
		border-bottom: none;
		padding-bottom:0;
		columns: 1;
		padding-top: 23px;
	}

	li {
		margin-left: 0;
		display: block;
		padding: 5px 0;

		@include media($large-up) {
			display: inline-block;
			margin-right: 20px;
			padding:0;

			&:last-child {
				margin-right:0;
			}
		}

		a {
			@include font-size(16);
			font-weight: 400;
			width: 100%;
			display: block;
			border: none;
			color: $primary-color;

			&:hover {
				color: $link-active-color;
			}

			@include media($large-up) {
				display: inline-block;
				width: auto;
			}
		}

		.active{
			color:$link-underline-color;
			font-weight: 600;
		}
	}
	
}

// Breadcrumb

.breadcrumbs-container {
	
	@include outer-container(1350px);
	padding: 0 30px;
	margin: 0 auto;
	display: none;
	
	
	@include media($medium-up) {
		display: block;
	}

	@include media($large-up) {
		margin-bottom:-5px;
	}

	ul#breadcrumb {
	
		list-style: none;
		padding-left: 0;
		margin: 0 0 1rem 0;
		@include font-size(16);
		font-weight: 300;

		@include media($large-up) {
			@include font-size(18);
		}
		
		li {
			
			display: inline-block;
			margin-right: .2rem;
			color: $black;

			&:after {
				content:" ";
				margin: 0 5px;
			}

			&.current {
				color: inherit;

				&:after {
					display: none;
				}
			}
		}
		
	}

}


// Footer

footer {
	background: $primary-color;
	padding: 64px 0 10px;
	margin-top: calc(64px);
	color: $white;
	position: relative;
	text-align: center;
	background-image: url(/assets/images/footer-bg.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@include media($large-up) {
		text-align: left;
		padding: 75px 0 60px;
		margin-top: calc(75px);
	}

	@media screen and (min-width: 1024px) and (max-width: 1100px) {
		.fourth:first-of-type {
			display: none !important;
		}

		.sixth, .fourth {
			@include span-columns(3);
		}
	}

	// &::before {
	// 	content:"";
	// 	width: 100%;
	// 	height: 88px;
	// 	display: block;
	// 	position: absolute;
	// 	top:-77px;
	// 	background: url('../images/ocy-pattern-primary.svg') center 0 repeat-x;
	// 	background-size: 325px 77px;
	// }

	.logo-symbol {
		
		display: inline-block;
		margin-top: 15px;
		width: 50px;
		height: 50px;
		background: url('../images/ocy-logo-symbol-white.svg') 0 0 no-repeat;
		background-size: 50px 50px;
		margin-bottom: 30px;

		@include media($large-up) {
			margin-top: 30px;
			margin-bottom:0;
			width: 100%;
			height: 40px;
			background: url('../images/ocy-logo-white.svg') 0 0 no-repeat;
			background-size: 173px 40px;	
		}
	}

	div p {
		font-weight: 300;
		font-size: 1rem;
		margin: 0;
		line-height: 2;

		&:first-of-type {
			margin-top: 0;
			margin-bottom: 30px;

			@include media($large-up) {
				margin-bottom:0;
				margin-top: 19px;
			}
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 30px;
		

		@include media($large-up) {
			margin-bottom:0;
			margin-top: 25px;
		}

		li {
			font-weight: 300;
			font-size: 1rem;
			margin: 10px 0;
		}

		&.follow-list {
			width: 100%;
			overflow: hidden;
			
			@include media($large-up) {
				margin-top: 30px;
			}

			li {
				padding:0;

				&:first-child {
					margin-top:0;
				}
				
				@include media($large-up) {
					margin:0 0 12px 0;
				}
			}

			a {
				font-weight: 300;
				border: none;
				
				position: relative;

				@include media($large-up) {
					padding-left: 38px;
					line-height: 29px;
					height: 30px;
					display: block;
				}

				&:hover {
					opacity: 0.65;
				}

				&:before {
					content:"";
					width: 100%;
					height: 30px;
					background-size: 30px 30px;
					background-repeat: no-repeat;
					background-position: center center;
					overflow: hidden;
					display: inline-block;
					display: none;
			

					@include media($large-up) {
						width: 30px;
						position: absolute;
						top:0;
						left:0;
						display: block;
						margin-right: 10px;
						display: inline-block;
					}
				}

				&.linkedin {
					&:before {
						background-image: url('../images/glyph-follow-linkedin.svg');
					}
				}

				&.subscribe {
					&:before {
						background-image: url('../images/glyph-follow-subscribe.svg');
					}
				}

				&.facebook {
					&:before {
						background-image: url('../images/glyph-follow-facebook.svg');
					}
				}
			}
		}
	}

	h3 {
		color: $white;
		font-family: $font-condenced;
		font-weight: normal;
		font-size: 1.125rem;
		margin-bottom: 12px;
		margin-top:0;
		text-transform: uppercase;
	}

	img {
		width: 160px;
		top: 2rem;
		display: block;
		@include media($large-up) {
			width: 220px;
			margin-top: .85rem;
		}
	}

	a, a:hover, a:active {
		color: $white!important;		
	}

	.copyright {

		display: none;

		@include media($large-up) {
			display: inline;
		}
		
		@include font-size(14);
		font-weight: 300;
		margin-top: 3px;

		a {
			border-bottom: 0;
			font-weight: 700;
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}
}
