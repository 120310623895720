.bcc-container * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    line-height: 1;
    box-sizing: border-box;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
}
.bcc-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 0;
    display: none;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 1rem;
    box-sizing: border-box;
    z-index: 99999;
    display: flex;
}
.elc-hidden {
    display: none !important;
}
.bcc-container .bcc-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
}
.bcc-page {
    display: none;
}
.bcc-page-active {
    display: block;
}
.elc-fullwidth .bcc-window .bcc-page-02,
.elc-small .bcc-window .bcc-page-01 {
    display: block !important;
}
.bcc-page-content {
    max-height: calc(100vh - 2rem);
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width: 831px) {
    .bcc-page-content {
        max-height: 80vh;
    }
}
.bcc-window {
    width: 100%;
    max-width: 800px;
    background: #f8f8f8;
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
}
.bcc-window-header {
    display: flex;
    padding: 2.5rem 3rem;
    background-color: #fff;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);
    align-items: center;
    height: 7rem;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
}
@media only screen and (max-width: 831px) {
    .bcc-window-header {
        padding: 1.5rem;
        height: auto;
    }
}
.bcc-header-title {
    display: flex;
    font-size: 1.5rem;
    font-weight: 400;
    color: inherit;
    flex: 1 1 auto;
}
@media only screen and (max-width: 831px) {
    .bcc-header-title {
        font-size: 0.9375rem;
    }
}
.bcc-header-logo {
    flex: 0 0 auto;
}
@media only screen and (max-width: 831px) {
    .bcc-header-logo {
        display: block;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}
.bcc-header-logo img, 
.bcc-header-logo svg {
    width: auto;
    height: 32px;
    object-fit: contain;
}
@media only screen and (max-width: 831px) {
    .bcc-header-logo img, 
    .bcc-header-logo svg {
        width: 80px;
        height: auto;
    }
}
.bcc-header-back {
    color: #000;
    display: inline-flex;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 700;
}
.bcc-header-back svg {
    fill: #000;
    margin-right: 0.25rem;
}
@media only screen and (max-width: 831px) {
    .bcc-header-back svg {
        top: -1px;
    }
}
@media (hover: hover) {
    .bcc-header-back:hover {
        opacity: 0.8;
    }
}
.bcc-window-content {
    padding: 3rem;
    flex: 1 1 auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 831px) {
    .bcc-window-content {
        padding: 2rem 1.5rem;
    }
}
.bcc-window-content p.bcc-ingress {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.3;
}
@media only screen and (max-width: 831px) {
    .bcc-window-content p.bcc-ingress {
        font-size: 1rem;
    }
}
.bcc-check-list {
    list-style: none;
    padding: 0;
    margin: 1.25rem 0 -0.5rem;
}
@media only screen and (max-width: 831px) {
    .bcc-check-list {
        margin: 0.75rem 0 -0.5rem;
    }
}
.bcc-check-list li {
    display: flex;
    padding: 0.5rem 0;
    line-height: 1.6;
    font-size: 1rem;
}
@media only screen and (max-width: 831px) {
    .bcc-check-list li {
        padding: 0.4rem 0;
        line-height: 1.6;
        font-size: 0.875rem;
    }
}
.bcc-check-list li svg {
    fill: rgba(0, 0, 0, 0.3);
    flex: 0 0 auto;
    position: relative;
    top: 5px;
}
@media only screen and (max-width: 831px) {
    .bcc-check-list li svg {
        height: 12px;
    }
}
.bcc-check-list li span {
    padding-left: 0.6rem;
    line-height: 1.6;
}
@media only screen and (max-width: 831px) {
    .bcc-check-list li span {
        padding-left: 0.5rem;
    }
}
.bcc-checkbox-container {
    margin-bottom: -1rem;
    margin-top: -1rem;
}
@media only screen and (max-width: 831px) {
    .bcc-checkbox-container {
        margin-bottom: -0.75rem;
        margin-top: -0.75rem;
    }
}
.bcc-checkbox-container label {
    display: flex;
    cursor: pointer;
    padding: 0.75rem 1rem;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    position: relative;
}
.bcc-checkbox-container label.disabled {
    cursor: default;
}
.bcc-checkbox-container label .bcc-checkbox-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.03);
    opacity: 0;
    pointer-events: none;
}
.bcc-checkbox-input input:focus + .bcc-checkbox-bg {
    opacity: 1;
}
.bcc-checkbox-input {
    flex: 0 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    top: 2px;
    z-index: 1;
}
@media only screen and (max-width: 831px) {
    .bcc-checkbox-input {
        width: 1.25rem;
        height: 1.25rem;
    }
}
.bcc-checkbox-input input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}
.bcc-checkbox-input-placeholder {
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}
.bcc-checkbox-input-placeholder svg {
    opacity: 0;
    position: relative;
    left: 0;
    fill: #000;
}
@media only screen and (max-width: 831px) {
    .bcc-checkbox-input-placeholder svg {
        height: 8px;
    }
}
.bcc-checkbox-container label .bcc-checkbox-input input:focus + .bcc-checkbox-input-placeholder {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.3);
}
@media (hover: hover) {
    .bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder svg {
        opacity: 0;
    }
    .bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder {
        border-color: rgba(0, 0, 0, 0.4);
    }
}
.bcc-checkbox-input input:checked + .bcc-checkbox-input-placeholder svg {
    opacity: 1 !important;
}
.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder {
    background-color: rgba(0, 0, 0, 0.06);
    border-color: rgba(0, 0, 0, 0.15);
}
.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder svg {
    opacity: 0.4 !important;
    fill: #000;
}
.bcc-checkbox-text {
    padding-left: 0.75rem;
    font-size: 0.875rem;
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 831px) {
    .bcc-checkbox-text {
        padding-left: 0.5rem;
    }
}
.bcc-checkbox-text-title {
    font-weight: 700;
    display: block;
    line-height: 1.6;
}
.bcc-checkbox-text-description {
    display: block;
    margin-top: 0;
    line-height: 1.6;
}
.bcc-window-footer {
    display: flex;
    padding: 2.5rem 3rem;
    background-color: #fff;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08);
    flex-shrink: 0;
    flex-grow: 0;
}
@media only screen and (max-width: 831px) {
    .bcc-window-footer {
        padding: 0.5rem 1.5rem;
        display: block;
    }
}
.bcc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.125rem;
    height: 56px;
    padding: 0 1.75rem;
    text-align: center;
    position: relative;
    cursor: pointer;
}
@media only screen and (max-width: 831px) {
    .bcc-button {
        height: 48px;
        padding: 0 1rem;
        font-size: 0.875rem;
        width: 100%;
        margin: 0.5rem 0;
    }
}
.bcc-button span {
    position: relative;
    z-index: 1;
}
.bcc-button:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 0;
}
@media (hover: hover) {
    .bcc-button:hover:after {
        opacity: 1;
    }
}
.bcc-button-primary {
    background-color: #000;
    color: #fff;
    flex: 0 0 auto;
    margin-left: 1.5rem;
    padding: 0 3.5rem;
}
@media only screen and (max-width: 831px) {
    .bcc-button-primary {
        margin-left: 0;
    }
}
.bcc-button-secondary {
    color: #000;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15);
    flex: 1 1 100%;
}
@media (hover: hover) {
    .bcc-button-secondary:hover:after {
        opacity: 0.25;
    }
}
.bcc-button-expanded {
    flex: 1 1 100%;
    margin-left: 0;
}
.bcc-cookie-tab {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 0.55rem;
    background-color: #f1f1f1;
    z-index: 997;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bcc-cookie-tab a {
    color: #000;
    text-decoration: none;
    user-select: none;
    font-weight: 400;
    font-size: 0.875rem;
    font-family: inherit;
    margin: 0;
    padding: 0;
}
#bcc-show-cookie-details {
    display: inline-block;
    margin-top: 0.3rem;
    text-decoration: underline;
    opacity: 0.8;
    color: #000;
    font-weight: 400;
}
#bcc-show-cookie-details:hover {
    text-decoration: none;
    opacity: 1;
}
#bcc-show-cookie-details.bcc-cookie-details-visible span:first-child,
#bcc-show-cookie-details span:last-child {
    display: none;
}
#bcc-show-cookie-details.bcc-cookie-details-visible span:last-child {
    display: inline;
}
.bcc-cookie-details {
    line-height: 1.5;
    margin-top: 1rem;
    display: none;
    margin-bottom: 0.25rem;
}
.elc-cookie {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0.8rem 1rem 0.55rem;
    position: relative;
    margin-bottom: 2px;
}
.elc-cookie-field {
    margin-bottom: 0.15rem;
    line-height: 1.5;
    font-size: 0.75rem;
}
.elc-cookie-field strong {
    font-weight: 700;
}
.bcc-cookie-details-visible + .bcc-cookie-details {
    display: block;
}
/* bcc reset */
.bcc-container * {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	line-height: 1;
	box-sizing: border-box;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
}

.bcc-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	z-index: 0;
	display: none;
	font-size: 16px;
	font-weight: normal;
	color: #000;
	padding: 16px;
	box-sizing: border-box;
	z-index: 99999;
	display: flex;
}

.elc-hidden {
	display: none !important;
}

	.bcc-container .bcc-background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.4);
		z-index: 0;
	}

	.bcc-page {
		display: none;
	}

	.bcc-page-active {
		display: block;
	}

	/* craft elc specific styles */
	.elc-small .bcc-window .bcc-page-01 {
		display: block !important;
	}

	.elc-fullwidth .bcc-window .bcc-page-02 {
		display: block !important;
	}

	.bcc-page-content {
		max-height: calc(100vh - 32px);
		display: flex;
		flex-direction: column;
	}

	@media only screen and (max-width: 831px) {
		.bcc-page-content {
			max-height: 80vh;
		}
	}

	.bcc-window {
		width: 100%;
		max-width: 800px;
		background: #F8F8F8;
		box-shadow: 0 10px 60px 0 rgba(0,0,0,0.15);
		position: relative;
		z-index: 1;
	}

		
		.bcc-window-header {
			display: flex;
			padding: 40px 48px;
			background-color: #fff;
			box-shadow: 0 1px 0 0 rgba(0,0,0,0.08);
			align-items: center;
			height: 96px;
			flex-shrink: 0;
			flex-grow: 0;
			position: relative;
		}

		@media only screen and (max-width: 831px) {
			.bcc-window-header {
				padding: 24px 24px;
				height: auto;
			}
		}

			.bcc-header-title {
				display: flex;
				font-size: 24px;
				font-weight: normal;
				color: inherit;
				flex: 1 1 auto;
			}

			@media only screen and (max-width: 831px) {
				.bcc-header-title {
					font-size: 15px;
				}
			}

			.bcc-header-logo {
				flex: 0 0 auto;
			}

			@media only screen and (max-width: 831px) {
				.bcc-header-logo {
					display: block;
					position: absolute;
					right: 24px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

				.bcc-header-logo img {
					width: auto;
					height: 32px;
					object-fit: contain;
				}

				@media only screen and (max-width: 831px) {
					.bcc-header-logo img {
						width: 80px;
						height: auto;
					}
				}

			.bcc-header-back {
				color: #000;
				display: inline-flex;
				text-decoration: none;
				font-size: 14px;
				font-weight: bold;
			}

			@media only screen and (max-width: 831px) {
				.bcc-header-back {
				
				}
			}

				.bcc-header-back svg {
					fill: #000;
					margin-right: 4px;
				}

				@media only screen and (max-width: 831px) {
					.bcc-header-back svg {
						top: -1px;
					}
				}

			@media (hover: hover) {
				.bcc-header-back:hover {
					opacity: 0.8;
				}
			}


		.bcc-window-content {
			padding: 48px;
			flex: 1 1 auto;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
		}

		@media only screen and (max-width: 831px) {
			.bcc-window-content {
				padding: 32px 24px;
			}
		}

			.bcc-window-content p.bcc-ingress {
				font-size: 20px;
				font-weight: bold;
				line-height: 1.3;
			}

			@media only screen and (max-width: 831px) {
				.bcc-window-content p.bcc-ingress {
					font-size: 16px;
				}
			}

			.bcc-check-list {
				list-style: none;
				padding: 0;
				margin: 20px 0 -8px 0;
			}

			@media only screen and (max-width: 831px) {
				.bcc-check-list {
					margin: 12px 0 -8px 0;
				}
			}

				.bcc-check-list li {
					display: flex;
					padding: 8px 0;
					line-height: 1.6;
					font-size: 16px;
				}

				@media only screen and (max-width: 831px) {
					.bcc-check-list li {
						padding: 6px 0;
						line-height: 1.6;
						font-size: 14px;
					}
				}

				.bcc-check-list li svg {
					fill: rgba(0,0,0,0.3);
					flex: 0 0 auto;
					position: relative;
					top: 5px;
				}

				@media only screen and (max-width: 831px) {
					.bcc-check-list li svg {
						height: 12px;
					}
				}

				.bcc-check-list li span {
					padding-left: 10px;
					line-height: 1.6;
				}

				@media only screen and (max-width: 831px) {
					.bcc-check-list li span {
						padding-left: 8px;
					}
				}

			.bcc-checkbox-container {
				margin-bottom: -16px;
				margin-top: -16px;
			}

			@media only screen and (max-width: 831px) {
				.bcc-checkbox-container {
					margin-bottom: -12px;
					margin-top: -12px;
				}
			}

				.bcc-checkbox-container label {
					display: flex;
					cursor: pointer;
					padding: 12px 16px;
					width: calc(100% + 32px);
					margin-left: -16px;
					position: relative;
				}

				.bcc-checkbox-container label.disabled {
					cursor: default;
				}

					.bcc-checkbox-container label .bcc-checkbox-bg {
						position: absolute;
						top:0;
						left:0;
						width: 100%;
						height: 100%;
						z-index: 0;
						background-color: rgba(0,0,0,0.03);
						box-shadow: inset 0 0 0 1px rgba(0,0,0,0.03);
						opacity: 0;
						pointer-events: none;
					}

					.bcc-checkbox-input input:focus + .bcc-checkbox-bg  {
						opacity: 1;
					}

					.bcc-checkbox-input {
						flex: 0 0 auto;
						width: 24px;
						height: 24px;
						position: relative;
						top: 2px;
						z-index: 1;
					}

					@media only screen and (max-width: 831px) {
						.bcc-checkbox-input {
							width: 20px;
							height: 20px;
						}
					}

						.bcc-checkbox-input input {
							opacity: 0;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							z-index: 0;
						}

						.bcc-checkbox-input-placeholder {
							width: 100%;
							height: 100%;
							border: 2px solid rgba(0,0,0,0.2);
							position: absolute;
							top: 0;
							left: 0;
							z-index: 1;
							display: flex;
							align-items: center;
							justify-content: center;
							user-select: none;
						}

							.bcc-checkbox-input-placeholder svg { 
								opacity: 0;
								position: relative;
								left: 0px;
								fill: #000;
							}

							@media only screen and (max-width: 831px) {
								.bcc-checkbox-input-placeholder svg { 
									height: 8px;
								}
							}

							.bcc-checkbox-container label .bcc-checkbox-input input:focus + .bcc-checkbox-input-placeholder {
								box-shadow: 0 0 0 4px rgba(0,0,0,0.05);
								border-color: rgba(0,0,0,0.3);
							}

							@media (hover: hover) {
								.bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder svg {
									opacity: 0;
								}

								.bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder {
									border-color: rgba(0,0,0,0.4);
								}
							}

							.bcc-checkbox-input input:checked + .bcc-checkbox-input-placeholder svg {
								opacity: 1 !important;
							}

							.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder {
								background-color: rgba(0,0,0,0.06);
								border-color: rgba(0,0,0,0.15);
							}

							.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder svg {
								opacity: 0.4 !important;
								fill: #000;
							}

					.bcc-checkbox-text {
						padding-left: 12px;
						font-size: 14px;
						position: relative;
						z-index: 1;
					}

					@media only screen and (max-width: 831px) {
						.bcc-checkbox-text {
							padding-left: 8px;
						}
					}

						.bcc-checkbox-text-title {
							font-weight: bold;
							display: block;
							line-height: 1.6;
						}

						.bcc-checkbox-text-description {
							display: block;
							margin-top: 0;
							line-height: 1.6;
						}

		.bcc-window-footer {
			display: flex;
			padding: 40px 48px;
			background-color: #fff;
			box-shadow: 0 -1px 0 0 rgba(0,0,0,0.08);
			flex-shrink: 0;
			flex-grow: 0;
		}

		@media only screen and (max-width: 831px) {
			.bcc-window-footer {
				padding: 12px 24px;
				display: block;
			}
		}

			.bcc-button {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				font-weight: bold;
				font-size: 18px;
				height: 56px;
				padding: 0 28px;
				text-align: center;
				position: relative;
				cursor: pointer;
			}

			@media only screen and (max-width: 831px) {
				.bcc-button {
					height: 48px;
					padding: 0 16px;
					font-size: 14px;
					width: 100%;
					margin: 8px 0;
				}
			}

			.bcc-button span { 
				position: relative;
				z-index: 1;
			}

			.bcc-button:after {
				content:"";
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
				background-color: rgba(0,0,0,0.15);
				opacity: 0;
			}

			@media (hover: hover) {
				.bcc-button:hover:after {
					opacity: 1;
				}
			}

			.bcc-button-primary {
				background-color: #000;
				color: #fff;
				flex: 0 0 auto;
				margin-left: 24px;
				padding: 0 56px;
			}

			@media only screen and (max-width: 831px) {
				.bcc-button-primary {
					margin-left: 0;
				}
			}

			.bcc-button-secondary {
				color: #000;
				font-weight: normal;
				background-color: rgba(0,0,0,0.05);
				box-shadow: inset 0 0 0 2px rgba(0,0,0,0.15);
				flex: 1 1 100%;
			}

			@media (hover: hover) {
				.bcc-button-secondary:hover:after {
					opacity: 0.25;
				}
			}

			.bcc-button-expanded {
				flex: 1 1 100%;
				margin-left: 0;
			}

.bcc-cookie-tab {
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 0 14px;
	background-color: #f1f1f1;
	z-index: 997;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bcc-cookie-tab a {
	color: #000;
	text-decoration: none;
	user-select: none;
	font-weight: normal;
	font-size: 14px;
	font-family: inherit;
	margin:0;
	padding:0;
}

/* bcc cookie details */
#bcc-show-cookie-details {
	display: inline-block;
	margin-top: 5px;
	text-decoration: underline;
	opacity: 0.8;
	color: #000;
	font-weight: normal;
}

#bcc-show-cookie-details:hover {
	text-decoration: none;
	opacity: 1;
}

#bcc-show-cookie-details span:last-child, 
#bcc-show-cookie-details.bcc-cookie-details-visible span:first-child {
	display: none;
}

#bcc-show-cookie-details.bcc-cookie-details-visible span:last-child {
	display: inline;
}

.bcc-cookie-details {
	line-height: 1.5;
	margin-top: 16px;
	display: none;
	margin-bottom: 4px;
}

	.elc-cookie {
		background-color: rgba(0,0,0,0.04);
		padding: 13px 16px 9px 16px;
		position: relative;
		margin-bottom: 2px;
	}

		.elc-cookie-field {
			margin-bottom: 2px;
			line-height: 1.5;
			font-size: 12px;
		}

		.elc-cookie-field strong {
			font-weight: bold;
		}

.bcc-cookie-details-visible + .bcc-cookie-details {
	display: block;
}