#chart-client-container {

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-color: rgba(#000, .1);
    z-index: 10000;
    overflow: auto;

    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 10001;
        background-color: #fff;
        border: none;
        box-shadow: 0 0 10px rgba(#000, .2);

        .inner-wrap {
            display: block;
            overflow: auto;

            @include media(320px) {
                width: 320px;
            }

            @include media(480px) {
                width: 480px;
            }

            @include media(769px) {
                //width: 640px;
            }
        }

        .content {
            padding: 40px 20px;
            color: #333;

            h2, h3, h4, h5 {
                text-align: center;
                margin: 0;
            }

            figure {
                padding: 0;
                margin: 0;
                margin-bottom: 15px;

                img {
                    max-width: 140px;
                    display: block;
                    margin: 0 auto;
                }
            }

            hr {
                border: 0;
                height: 1px;
                margin: 15px auto;
                background: #ccc;
                background-image: linear-gradient(to right, #f5f5f5, #ccc, #f5f5f5);
            }

            p {
                margin-bottom: 0;
                text-align: center;
            }

            .bar {
                width: 100%;
                margin: 15px auto;
                height: 15px;
                border: solid 1px rgba(#000, .1);
                border-radius: 24px;
                overflow: hidden;

                div {
                    height: 15px;
                }
            }

            .close {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                background-color: #cd2b3a;
                color: white;
                text-align: center;;
                text-decoration: none;
            }
        }
    }

}