// BASE

body, html {
	font-size: $rem-base;
	font-family: $font-sans-serif;
	margin: 0 auto;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $black;
	background-color: $primary-color;
}

body {

	&.active {
		@media only screen and (max-width: 1039px) {
			height: 100%;
			overflow: hidden;
		}
	}
}

.site-wrapper {
	background-color: white;
	overflow: hidden;
	position: relative;
	width: 100%;
	

	// &:before {
	// 	content:"";
	// 	width: 162px;
	// 	height: 66px;
	// 	background: url('../images/ocy-pattern-primary-long.svg') 0 0 no-repeat;
	// 	background-size: 162px 66px;
	// 	position: absolute;
	// 	top: -22px;
	// 	left: -45px;
	// 	transform: rotate(-30deg);
	// 	opacity: 0.05;
	// 	z-index: 999;

	// 	@media only screen and (min-width: 641px) {
	// 		width: 325px;
	// 		height: 133px;
	// 		background-size: 325px 133px;
	// 		top: -45px;
	// 		left: -90px;
	// 	}

	// 	@media only screen and (min-width: 1920px) {
	// 		position: fixed;
	// 		z-index: 0;
	// 	}
	// }
}

a {
	color: $secondary-color;
	text-decoration: none;
	border-bottom: 1px solid $link-underline-color;
	@include transition(all .15s ease-in-out);
	font-weight: 600;
}

a.subscribe-link{
	font-size: 0.8em;
}

a:hover {
	color: $link-active-color;
	border-bottom: 1px solid transparent;
}

// Buttons

.button {
	border: 2px solid $third-color;
	padding: .5rem .8rem;
	&:hover {
		border: 2px solid $secondary-color;
	}
}

h1 {
	font-family: $font-condenced;
	color: $primary-color;
	text-transform: uppercase;
	font-weight: 700;
	@include font-size(34);
	margin:0;
	line-height: 1;

	@include media($large-up) {
		@include font-size(48);
	}

	@media only screen and (min-width: 1440px) {
		@include font-size(58);
	}
}


h2,h3,h4,h5 {
	font-family: $font-condenced;
	text-transform: uppercase;
	font-weight: 700;
	color: $primary-color;
	line-height: 1.2;
}

h1,h2,h3,h4,h5 {
	a {
		color: inherit;
		border-bottom: none;
	}
}


h2 {
	&.heading {
		text-transform: uppercase;	
		@include font-size(20);
		font-family: $font-condenced;
		margin-bottom: 30px;
		margin-top:-4px;

		@include media($large-up) {
			@include font-size(22);
			margin-bottom: 35px;
		}
	}
}

h3 {
	
	@include font-size(18);

	&.heading {
		@include font-size(18);
		text-transform: uppercase;
		font-family: $font-condenced;
	}
}

h4 {
	@include font-size(20);
	margin-bottom: 20px;
	margin-top:-4px;

	@include media($large-up) {
		@include font-size(22);
		margin-bottom: 25px;
	}
}

article.article {

	table.default {
		margin-bottom: 60px;
		
		tr {
			td, th { 
				@include font-size(16);
				padding: 5px 15px;
			}

			th {
				color: $secondary-color;
			}

			&.head {
				th {
					color: $black;
					padding: 22px 20px;
				}
				
			}
		}
		
	}

	table.dividend {
		tr {
			td, th {

				background-color: white;
				padding: 10px 15px;
			}

			&.dividend_total {
				td {
					
				}
			}

			&:hover {
				td, th {
					background-color: white !important;
				}
			}
		}

		tbody tr:hover {
			th {
				background-color: $gray !important;
			}
		}

		tbody tr th {
			background-color: $gray;
			color: $black;
			font-family: $font-condenced;
			text-transform: uppercase;
		}
	}

	.body {

		h2,h3,h4,h5 {
			font-family: $font-sans-serif;
			text-transform: none;
			font-weight: 700;
		}

		h2 {
			
			@include font-size(22);
			margin-bottom:25px;

			@include media($large-up) {
				@include font-size(26);
				margin-bottom: 35px;
			}
		}

		h3, h4 {
			
			@include font-size(18);
			margin-bottom:20px;

			@include media($large-up) {
				@include font-size(22);
				margin-bottom: 30px;
			}
		}

		h5, h6 {
			@include font-size(16);
			margin-bottom:10px;

			@include media($large-up) {
				@include font-size(20);
			}
		}
		
		p, ul, ol {
			
			line-height: 1.8;
			@include font-size(16);
			font-weight: 400;
			margin: -3px 0 30px 0;

			@include media($large-up) {
				@include font-size(18);
				margin: -3px 0 35px 0;
			}

			@media only screen and (min-width: 1440px) {
				@include font-size(20);
			}

		}

		ul,ol {

			padding: 20px 20px 20px 35px;
			width: 100%;
			background-color: $gray;
			@include font-size(14);
			line-height: 1.6;

			@include media($large-up) {
				padding: 36px 45px 36px 55px;
				@include font-size(18);
				margin-bottom: 45px;
			}

			li {
				margin-bottom: 15px;
				padding-left: 5px;
				&:last-child {
					margin-bottom:0;
				}
			}
		}

	}
}

table {
	p {
		font-size: 1rem !important;
		margin:0 !important;
	}

	ul,ol {
		background-color: transparent !important;
		padding: 0 !important;
		margin: 10px 0 !important;
		padding-left:15px !important;
		font-size: 1rem !important;

		li {
			padding-left: 0px !important;
		}
	}
}

.dividenNotice {
	font-weight: 300;
	line-height: 1.8;
	margin-top: -10px;
	margin-bottom:-10px;
	@include font-size(14);

	@include media($large-up) {
		@include font-size(16);
	}
}

.center {
	text-align: center;
}


// Responsive images //

img {
	width: 100%;
}

.ir_contact-container {

	padding-top: 10px;

	.ir_contact {
		width: 100px;
		margin-right: 30px;
	}

	p {
		padding-top:5px;
	}
}

// Forms
.formWrapper {
	@include media($large-up) {
		width: 100%;
		max-height: 250px;
	}

	form {
		background-color: $primary-color;
		margin-top: 1rem;
		padding: 30px;

		@include media($large-up) {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.row {
			margin-bottom: 0.5rem;
		}
	}

	input, select, textarea {
		padding: 0.5rem 0.9rem;
		font-size: 1rem;
		font-weight: 400;
		margin:0 0 10px 0;
		border: 1px solid rgba(255,255,255,0.2);
		display: block;
		background-color: rgba(255,255,255,0.05);
		font-family: $font-sans-serif;
		color: $white;
		@include transition(all .15s ease-in-out);
		width: 100%;
		@include media($large-up) {
			display: inline;
			width: 97%;
			margin: .5rem .5rem .5rem .5rem;
			margin-top:0;
			margin-bottom:0;
		}

		&::placeholder {
			color: white;
		}

		&:first-of-type {
			margin-left:0;
		}
	}

	input:focus {
		border: 1px solid white;
		outline: none;

		
	}

	label {
		color: $primary-color;
		display: block;
	}

	h2 {
		text-align: center;
	}

	button {
		
		font-size: 1rem;
		font-family: $font-sans-serif;
		cursor: pointer;
		width: 100%;
		font-weight: 700;
		color: $white;
		background-color: $secondary-color;
		border: 1px solid $secondary-color;
		@include transition(all .15s ease-in-out);

		@include media($large-up) {
			width: 97%;
			margin-left: 10px;
		}

		&:focus {
			outline: none;
		}

		&:hover {
			background-color: $secondary-color-alt;
			border: 1px solid $secondary-color;
		}
	}
}
// Responsive tables from CSS-TRICKS.COM
// https://css-tricks.com/responsive-data-tables/

table.reports th, table.reports tr {
	width:33%!important;
}


table { 
	
	width: 100%; 
	border-collapse: collapse;
	opacity: 1;
	@include transition(opacity .15s ease-in-out);

	a{
		text-decoration:underline;
		color: #004486;
	}
	
	@media only screen and (min-width: 901px) {
		img.fleet-icon {
			max-width: 80px;
			margin-right: .5rem;
			vertical-align: middle;
		}
	}

	&.invisible {
		opacity: 0;
	}

	&:hover {
		tr td {
			//background-color: transparent !important;
		}
	}

	tr {
		
		&:nth-of-type(odd) { 

			td {
				background: #F9FBFC;
			}
  			
		}

		&.link-row {
			cursor: pointer;
		}

		&:hover {
			td {
				background-color: $light-gray !important;
			}
		}

		&.head {
			
			background: $white;

			th {
				color: $secondary-color;
				font-family: $font-condenced;
				text-transform: uppercase;
			}
			
		}

		th {
			font-family: $font-condenced;
			text-transform: uppercase;
		}

		td, th { 
			@include font-size(16);
			padding: 15px 5px;
			text-align: left;
			border-bottom: 1px solid $border-color;
			line-height: 1.4;
			@include transition(background-color .15s ease-in-out);
			
			@media only screen and (min-width: 901px) {
				&.unit {
					//width: 15%;
				}

				&.type {
					width: 20%;
				}

				&.built {
					width: 100px;
				}
				
				&.year {
					padding-left: 3px;
					padding-right: 3px;
				}



				&.icon {
					padding-right:0;
					width: 70px;
					max-width: 70px;
					
				}

				&:first-child {
					padding-left: 15px;
				}

				&:last-child {
					padding-right: 15px;
				}
			}

			&.icon {
				display: none;
				@media only screen and (min-width: 901px) {
					display: table-cell;
				}
			}

			&.sorted {
				font-weight: 600;
			}

			a {
				//color: $black;
				border: none !important;
				font-weight: 400;
			}
			
			.fleet-icon {
				display: inline-block;
				overflow: hidden;
				width: 45px;
				height: 45px;
				float: left;
				vertical-align: middle;
				position: relative;

				img {
					position: absolute;
					top:0;
					left:0;
					height: 100% !important;
					margin:0;
					width: 100%;
					height: auto;
					object-fit: contain;
				}
			}

		}
	}

	th[role=columnheader]:not(.no-sort) {
		cursor: pointer;
		transition: color .15s ease-in-out;
	}

	th[role=columnheader]:not(.no-sort):after {
		content: "";
		margin-top: 8px;
		margin-left: 4px;
		position: absolute;
		border-width: 0 5px 5px;
		border-style: solid;
		border-color: $secondary-color transparent;
		visibility: hidden;
		opacity: 0;
		-ms-user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		transition: opacity .15s ease-in-out, border-color .15s ease-in-out;
	}

	th[aria-sort=ascending]:not(.no-sort):after {
		border-bottom: none;
		border-width: 5px 5px 0;
		border-color: $secondary-color transparent;
		margin-top:9px;
	}

	th[aria-sort]:not(.no-sort):after {
		visibility: visible;
		opacity: 1;
	}

	th[role=columnheader]:not(.no-sort):hover {
		color: $link-active-color;
	}

	th[role=columnheader]:not(.no-sort):hover:after {
		visibility: visible;
		border-color: $link-active-color transparent;
		opacity: 1;
	}
}

footer a {
	color: white;
}

/* From legacy - Notification service - Start */
.feedbackcollectorWrapper h3{
	font-weight:900;
	font-size:1.2rem;
}

.feedbackcollectorWrapper  {
	padding:20px;
	background:#e4e4e4;
}

.feedback-collector-block  {
	 margin:0 0 13px 0;
}

.feedback-collector-block label  {
/*	text-transform:uppercase;
	font-weight:bold;
*/
}

.feedback-collector-block input[type="text"], .feedback-collector-block input[type="password"], .feedback-collector-block textarea  {
	background:#ffffff;
	border:0;
	padding:7px;
	display:block;
	width:97%;
	font-family:inherit;
	font-size:inherit;
}

.feedback-collector-block input[type="radio"]{
	width:40%!important;
	float:right!important;
}

.feedback-collector-buttonblock input  {
	 margin-top:16px;
}

.feedback-collector-options-captcha img  {
	border:1px solid #ffffff;
	display:block;
	float:left;
	margin:0 8px 0 0;
}

.feedback-collector-options-captcha input  {
	float:left;
	width:80px !important;
}

.feedback-collector-error > span {
	color: #FF0000;
	padding: 0 0 0 20px;
}
/* From legacy - Notification service - End */

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media screen and (max-width: 900px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block;
		width: 100%;
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	tr { 
		
	}
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid $border-color; 
		position: relative;
		padding-left: 50%; 
	}
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	#table-fleet td:nth-of-type(2):before { content: "Unit"; }
	#table-fleet td:nth-of-type(3):before { content: "Type"; }
	#table-fleet td:nth-of-type(4):before { content: "Build"; }
	#table-fleet td:nth-of-type(5):before { content: "Counterparty"; }
	#table-fleet td:nth-of-type(6):before { content: "Years"; }
}

.singleNews{
	section{
		img{
			max-width: 20%;
			margin: 1rem 1rem 1rem 0rem;
		}
	}
	
}
.newsListing{
	
	border-bottom: 1px solid $border-color;
	padding: 15px 0 45px 0;

	&:first-of-type {
		padding-top:0;
	}

	&:last-of-type {
		border-bottom: none;
	}
	
	i{
		@include font-size(14);
		color: $text-gray;
		margin-left: 0;
		font-weight: normal;
		font-style: normal;
		margin-bottom: 15px;
		display: block;
		margin-top: 4px;
	}
	h2{
		margin-bottom:0 !important;
	}

	p {
		margin-bottom:10px !important;
	}
}


.pagination{
	padding: 1rem 1rem;
	margin: 1rem 1rem; 
	display: flex;
	justify-content: center;
	align-items: center;
	span{
		margin: 10px;
	}
	a{
		margin: 10px;
	}
}

.label-container {
	&:before {
		content:"PDF";
		height: 18px;
		line-height: 18px;
		background-color: $secondary-color;
		color: $white;
		display: inline-block;
		border-radius: 1px;
		padding: 0 5px;
		@include font-size(11);
		text-transform: uppercase;
		font-family: $font-condenced;
		font-weight: bold;
		position: relative;
		top:-1px;
		margin-right: 2px;
	}
}

section.container.boardmembers {
	
	padding-bottom: 25px;
	position: relative;
	padding-top:0;
	padding-left:0;
	padding-right:0;
	
	@include media($large-up) {
		border-bottom: 1px solid $border-color;
		padding-top:45px;
		
		&:last-of-type {
			border: none;
		}
	}

	.half {
			
		padding-bottom: 30px;

		@include media($large-up) {
			padding-bottom:0;

			&:first-child {
			padding-right: 30px;
		}

			&:last-child {
				padding-left: 30px;
			}
		}
		}

	.boradMemberImage {
		
		margin-bottom:25px;
		
		@include media($large-up) {
			padding-right: 15px;
			margin-bottom:0;
		}
	}

	h2 {
		margin-top:-4px;
		margin-bottom: 4px;
	}

	@include media($large-up) {

		&:before {
			content:"";
			width: 1px;
			height: 100%;
			position: absolute;
			top:0;
			left:50%;
			margin-left:-1px;
			border-right: 1px solid $border-color;
		}

	}
}

.boardmembers:first-of-type {
	padding-top:0 !important;
	@include media($large-up) {
		margin-top: 75px;
	}
}

.client-logo{
	max-width:140px;
	margin-bottom:25px;
}


article.article {

	img {
		width: auto;
		margin-bottom: 25px;
		margin-top: 0px;

		@include media($large-up) {
			margin-top: 10px;
			margin-bottom: 40px;
		}

		&.alignLeft {
			float: left;
			margin: 7px 30px 2px 0 !important;
		}

		&.alignRight {
			float: right;
			margin: 7px 0 2px 20px !important;
		}
	}

	.ingress {

		margin-bottom: 35px;

		@include media($large-up) {
			margin-bottom: 45px;
		}

		p {
			font-weight: 300;
			line-height: 1.6;
			margin-bottom:0;
			text-align: left;
			margin-bottom:-6px;
			@include font-size(20);
			
			@include media($large-up) {
				@include font-size(24);
				margin-bottom:0;
				line-height: 1.5;
			}
	
			@media only screen and (min-width: 1441px) {
				@include font-size(30);
			}
	
			&:last-child {
				margin-bottom:0;
			}
	
		}
	}

	h1 {
		margin-bottom: 1.5rem;

		@include media($large-up) {
			margin-bottom: 2.5rem;
		}
	}

}

.boradMemberImage img {
	width: 100% !important;
	margin:0 !important;
}

.hidden-input {
	width: 0;
	position: absolute;
}

section.container.press-release {
	p, p * {
		margin-top: 0 !important;
		font-size: $rem-base !important;
		font-family: $font-sans-serif !important;
	}

	p {
		margin-bottom: 1.6em;
	}
}
