
// GRID 

// Container

section.container {
  	@include outer-container(1350px);
  	padding: 30px;
  	@include media($large-up) {
  		margin: 0 auto;
  		padding: 45px 30px;
  	}
}

section.container-small {
  	@include outer-container(1000px);
  	padding: 15px;
  	@include media($large-up) {
  		margin: 0 auto;
  	}
}
section.margin-top {
	margin-top: 1rem;
}


.row {
	@include row;
}

// Columns

.half {
	@include media($large-up) {
		@include span-columns(6);
	}
}

.half-centered {
	@include media($large-up) {
		@include span-columns(6);
		@include shift(3);
	}
}

.third {
	
	width: 100%;

	@include media($large-up) {
		@include span-columns(4);
	}
}

.fourth {
	@include media($large-up) {
		@include span-columns(3);
	}
}

.sixth {
	
	width: 100%;

	@include media($large-up) {
		@include span-columns(2);
	}
}

.two-third {
	@include media($large-up) {
		@include span-columns(8);
	}
}




// Helpers

.hide-large-up {
	@include media($large-up) {
		display: none;
	}
}
.hide-for-small {
	display: none;
	@include media($large-up) {
		display: initial;
	}
}

.container.padding {
	padding: 2rem 0;
}
.float-right {
	float: right;
}

.col-center {
	float: none;
	margin: 0 auto !important;
}