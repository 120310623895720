/* affectig all bcc styles */
.bcc-container * {  }

.bcc-container a, #elc-tab-link {
    text-decoration: none;
    border-bottom: 0;
}

/* header title */
.bcc-header-title { font-size: 1.4rem; font-family: 'Open Sans Condensed', sans-serif; text-transform: uppercase; font-weight: bold; color: #001f3d; }
@media only screen and (max-width: 831px) {
.bcc-header-title { font-size: 0.9375rem; }
}


/* ingress text */
.bcc-window-content p.bcc-ingress { /* font-size: 1.25rem; */ color: #001f3d; }
@media only screen and (max-width: 831px) {
.bcc-window-content p.bcc-ingress { /* font-size: 1rem; */ }
}


/* ul list */
.bcc-check-list li { /* font-size: 1rem; padding: 0.5rem 0; line-height: 1.6; */ }
@media only screen and (max-width: 831px) {
.bcc-check-list li { /* font-size: 0.875rem; padding: 0.4rem 0; line-height: 1.6; */ }
}


/* checkbox text */
.bcc-checkbox-text { /* font-size: 0.875rem; padding-left: 0.75rem; */ }
@media only screen and (max-width: 831px) {
.bcc-checkbox-text { /* font-size: 0.875rem; padding-left: 0.5rem; */ }
}

.bcc-check-list li svg {
	fill: #AE9A64;
}

.bcc-checkbox-text-title {
	color: #001f3d;
}


/* header */
.bcc-window-header { /* background-color: #fff; color: #000; box-shadow: 0 1px 0 0 rgba(0,0,0,0.08); */ }


/* buttons */
.bcc-button {
	font-size: 1rem; text-transform: uppercase; font-family: 'Open Sans Condensed';
}
@media only screen and (max-width: 831px) {
.bcc-button { font-size: 0.75rem; }
}


/* primary button */
.bcc-button-primary { background-color: #004486; }


/* secondary button */
.bcc-button-secondary { background-color: rgba(0,68,134,0.05); box-shadow: inset 0 0 0 2px rgba(0,68,134,0.2); color: #001f3d; font-weight: bold; }
.bcc-button-secondary:hover { border:none;  }


/* header back link */
.bcc-header-back { color: #004486; }
.bcc-header-back svg { fill: #004486; }


/* checkbox tick color */
.bcc-checkbox-input-placeholder svg { fill: #004486; }


/* checkbox tick color */
.bcc-header-logo img { /* width: auto; height: 32px; */ position: relative; top: 1px; }

@media only screen and (max-width: 831px) {
	.bcc-header-logo img { width: 82px; height: auto; position: relative; top: 2px; }
}


/* fixed right corner cookie tab link */
.bcc-cookie-tab { background-color: #F2F7F9; }
.bcc-cookie-tab a { color: #001f3d; }