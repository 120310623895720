// Styling for content boxes

.column-box {
	
	@media only screen and (max-width: 1039px) {
		height: auto !important;
	}

	padding-bottom: 45px;
	
	@include media($large-up) {
		margin-bottom: 0;	
		border-right: 1px dashed $border-color-dashed;
		padding-right: 30px;
		padding-bottom:0;
	}

	&:last-child {
		border:none;
		margin-bottom:0;
		padding-bottom:0;
	}

	h2 a {
		border-bottom: 0; 
	}

}


// Featured image 

img.featured-image {
	border: 2px solid $primary-color;
}

// Graphs

#graph-dividend-history {
	height: 155px;
	margin-top: -10px;
	margin-left: -10px;
	margin-right:-10px;
	margin-bottom:-30px;

	@include media($large-up) {
		margin-bottom:0px;
	}

	.highcharts-container {
		
		.highcharts-title {
			display: none;
		}

		.highcharts-tooltip {
			
			text {
				
				tspan {
					font-family: $font-sans-serif;
					color: $primary-color !important;
					font-size: 11px !important;
					fill: $primary-color !important;

					@include media($large-up) {
						font-size: 12px !important;
					}
				}
			}
		}

		.highcharts-data-labels {
			g {
				
				position: static;

				text {
					font-family: $font-condenced;
					position: absolute;
					font-size: 10px !important;
					top:0;
					text-shadow: none !important;

					@include media($large-up) {
						font-size: 12px !important;
					}
				}
			}
		}
		
		.highcharts-series {
			rect {
				fill: rgba(255,255,255,0.3);
			}
		}

		.highcharts-markers {
			path {
				transition: all .15s ease-in-out;
			}
		}

		.highcharts-axis-labels {
			text {
				font-size: 10px !important;
				font-family: $font-sans-serif;
				fill: white !important;

				@include media($large-up) {
					font-size: 12px !important;
				}
			}
		}

	}
}

#graph-share, #graph-daily-dividend-history {
	height: 380px; 
}

#ship-bar {
	height: 200px;
	width: 400px;
}

// Dividend Yield box

.dividend-yield {
	
	@include row;
	color: inherit;
	

	@include media($medium-up) {
		display: flex;
		flex-wrap: no-wrap;
	}

	.dividend-yield-box {

		width: 100%;
		text-align: center;
		margin: -40px 0 10px 0;

		@include media($medium-up) {
			display: flex;
			text-align: center;
			align-items: center;
			flex-shrink: 0;
			width: 120px;
			text-align: left;
			margin: 0;
		}

		@media only screen and (min-width: 1300px) {
			width: 160px;
		}

		.dividend-yield-number {
			
			@include font-size(48);
			font-family: $font-condenced;
			display: inline-block;

			@include media($medium-up) {
				@include font-size(50);
				display: block;
				position: relative;
				top:-1px;
			}

			@media only screen and (min-width: 1300px) {
				@include font-size(64);
			}
		}
		
	}

	.dividend-details {

		@include media($medium-up) {
			display: flex;
			flex-grow: 1;
		}

		ul {
			padding:0;
			list-style: none;
			text-align: left;
			margin: 0 0 -15px 0;
			font-weight: 300;
			width: 100%;


			@include media($medium-up) {
				border-left: 1px solid rgba(255,255,255,0.2);
				margin:0;
			}

			li {
				border-bottom: 1px solid rgba(255,255,255,0.2);
				position: relative;
				height: 45px;
				line-height: 45px;
				padding: 0;
				@include font-size(13);

				@include media($medium-up) {
					padding: 0 0 0 20px;
					@include font-size(14);
				}

				@media only screen and (min-width: 1300px) {
					@include font-size(16);
				}

				.share-number {
					right: 0;
					position: absolute;
					font-weight: 600;
					padding-right: 45px;

					@include media($medium-up) {
						padding-right: 55px;
					}
					
					&:last-child {
						padding-right: 0;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

// News list box

ul.news-list {
	
	list-style: none; 
	padding:0;
	padding-left: 0;
	padding-right: 0;
	margin:-15px 0 -15px 0;

	@include media($large-up) {
		margin:-15px 0 0 0;
	}

	li {
		color: $secondary-color;
		width: 100%;
		max-width: 100%;
		overflow:hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding:0;
		position: relative;
		border-bottom: 1px solid $border-color;
		padding: 18px 50px 18px 0;

		.date {
			@include font-size(12);
			display: block;
			color: $third-color;
			font-weight: 600;
			text-transform: uppercase;
			position: absolute;
			right: 0;
			text-align: right;
			height: 11px;
			top: 50%;
			margin-top: -10px;
		}

		.company-presentation {
			width: 100%;
			display: block;
			margin-bottom: 10px;

			img {
				width: 100%;
				@include media($large-up) {
					width: 90%;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}

		&:not(.underline-link) {
			a {
				@include font-size(16);
				border-bottom: none;
				font-weight: 400;	
			}
		}

		&.underline-link {
			
			@include media($large-up) {
				margin-bottom: -30px;
			}
		}
	}
}

// Document list

ul.document-list {
	list-style: none; 
	padding-left: 0;
	
	li {
		margin-bottom: 1rem;
	}
}
.file-size {
	font-size: .7rem;
	
	font-weight: 700;
}

// Fleet table

.fleet-container {
	@include media($large-up) {
		padding-top:30px !important;
	}
}

table#table-fleet {
	margin-top: 0px;
	
	@include media($large-up) {
		margin-top: 3rem;
	}

	.hide-graph-for-small {
		display: none;
		@include media($large-up) {
			display: table-cell;
		}
	}

	.dayrate-sorting-value {
		width: 0;
		display: block;
		height:0;
		position: absolute;
		z-index:0;
		visibility: hidden;
		top:0;
		left:0;
	}

	td {
		@media only screen and (max-width: 900px) {
			padding-left: 50%;
			border-bottom: none;
			@include font-size(13);
			font-weight: normal !important;
			padding-top: 5px;
			padding-bottom: 5px;
			background: white !important;
			&:before {
				@include font-size(13);
				font-weight: bold;
				top: 5px !important;
				padding-left: 0;
			}

			&.unit span {
				color: $secondary-color;
				border-bottom: 1px solid $link-underline-color;
				font-weight: bold;
			}
		}
	}

	tr {
		@media only screen and (max-width: 900px) {
			border-bottom: 1px solid $border-color;
			padding: 20px 0;
		}
	}

	th.year {
		min-width: 440px;

		span {
			text-align: center;
			display: block;
			float: left;
		}
	}
}

// Graphs for fleet
.graph-fleet-container {
	
	display: block;
	height: 18px;
	border-radius: 1000px;
	width: 100%;
	position: relative;

	&:before {
		content:"";
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		border-radius: 1000px;
		border: 1px solid #DDDCDC;
		background: #fff url('../images/graph-pattern.svg') 0 0 no-repeat;
		z-index: 0;
	}

	.graph {
		border-radius: 1000px;
		overflow: hidden;
		height: 18px;
		width: 100%;
		position: relative;
	}

	.divider {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		overflow: visible;
		display: flex;
  		flex-wrap: nowrap;

		div[class^="graph-fleet"] {
			background: transparent !important;
			display: block;

			&:after {
				display: block;
			}
		}

		div.charter-end {
			flex-grow: 1;
			min-width:0%;
			height: 18px;
			float: left;
			display: inline-block;
			position: relative;
			z-index: 1;
		}
	}

	.popup {
		
		position: relative;
		text-align: center;

		&:before {
			content: attr(data-graph-value);
			width: 225px;
			color: white;
			padding: 0 10px;
			@include font-size(13);
			text-align: center;
			position: absolute;
			z-index: 2;
			background: transparent url('../images/graph-popup-triangle.svg') center bottom no-repeat;
			top:-38px;
			height: 36px;
			line-height: 29px;
			opacity: 1;
			left: 50%;
   			transform: translateX(-50%);
   			opacity: 0;
			visibility: hidden;
			@include transition(all .15s ease-in-out);
			min-width: 154px;
		}

		&:hover {
			&:before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	div[class^="graph-fleet"] {
		float: left;
		height: 18px;
		display: inline-block;
		position: relative;
		z-index: 1;

		&:after {
			content:"";
			width: 1px;
			height: 28px;
			position: absolute;
			top:-5px;
			right:0;
			display: none;
		}

		&.graph-fleet__under-construction {
			background: #BDD1E0;

			&:after {
				display: none;
			}
		}

		&.graph-fleet__fixed-dayrate {
			background: $secondary-color;

			&:after {
				background: $secondary-color;
			}

			&:before {
				min-width: 170px;
			}
		}

		&.graph-fleet__floating-dayrate {
			background: $primary-color;

			&:after {
				background: $primary-color;
			}

			&:before {
				min-width: 185px;
			}
		}
	}
}

ul#table-colors {
	
	list-style: none;
	background-color: $light-gray;
	padding: 17px;
	width: 100%;
	display: none;
	
	@include media($large-up) {
		margin:-15px 0 0 0;
		float: right;
		width: auto;
		border-radius: 1000px;
		display: block;
	}

	li {
		display: block;
		margin:0;
		padding:0;
		font-size: 1rem;
		margin: 15px 0;
		line-height: 15px;
		padding-left: 24px;
		position: relative;
		width: 100%;
		@include media($large-up) {
			float: left;
			width: auto;
			height: 17px;
			margin:0;
			margin-right: 12px;
			margin-left: .4rem;
		}
	}
	li::before {
		content: "";
		position: absolute;
		height: 17px;
		width: 17px;
		background: black;
		display: inline-block;
		border-radius: 100px;
		vertical-align: middle;
		left:0;
		top:0;
	}

	li:nth-child(1)::before {
		background: #BDD1E0;
	}

	li:nth-child(2)::before {
		background: $secondary-color;
	}

	li:nth-child(3)::before {
		background: $primary-color;
	}

	li:nth-child(4)::before {
		background: #fff url('../images/graph-pattern.svg') center center no-repeat;
		box-shadow: inset 0 0 0 1px #DDDCDC;
	}
}

// Tabs



ul.tabs{ 
		margin: 0px;
		padding: 0px;
		list-style: none;
		border-bottom: 1px solid $border-color;

	li{
		background: none;
		color: $primary-color;
		display: block;
		padding: 13px 20px 10px 20px;
		cursor: pointer;
		@include font-size(20);
		font-weight: 700;
		font-family: $font-condenced;
		@include transition(all .15s ease-in-out);
		width: 100%;
		border-bottom: 1px solid $border-color;

		&:last-child {
			border-bottom: none;
		}

		@include media($medium-up) {
			display: inline-block;
			width: auto;
			padding: 13px 25px 10px 25px;
			border: none;
		}

		&:hover {
			color: $link-active-color;
		}

		&.current{
			color: $link-underline-color;
			

			@include media($medium-up) {
				
				box-shadow: 1px 0 0 0 $border-color, -1px 0 0 0 $border-color, 0 -1px 0 0 $border-color;
				position: relative;

				&:after {
					content:"";
					width: 100%;
					height: 1px;
					background-color: white;
					position: absolute;
					bottom:-1px;
					left:0;
					z-index: 1;
				}
			}

			
		}
	}
}

.tab-content{
	display: none;
	padding: 0;
}

table.tab-list {
		tr {

			&:hover {
				td {
					background-color: white !important;
				}
			}

			td {
				padding: 20px 20px !important;
				background-color: white;

				@include media($medium-up) {
					padding: 22px 20px !important;
				}
				
				.label-container {
					&:before {
						margin-right: 6px;
						@include media($medium-up) {
							margin-right: 10px;	
						}
					}

					&.link {
						&:before {
							content:"Link";
						}
					}
				}

				color: $third-color;

				a {
					color: $secondary-color;

					&:hover {
						color: $link-active-color;
					}
				}

			}
		}
	}

.tab-content.current{
	display: inherit;
}

// People

.title {
	color: $third-color;
}

// Timeline

section#timeline {
	margin: 60px 0;
	position: relative;
	#border {
		@include media($large-up) {
			border-left: 1px solid $primary-color;
			height: 100%;
			margin-left: 50%;
			position: absolute;
		}
	}
		
	h2 {
		margin-top:-5px;
		margin-bottom: -5px;
	}

	.timeline-left {
		left: 0;
		clear: both;
		&::after {
			display: table;
			clear: both;
			content: "";
		}
		.half {
			position: relative;
			padding-top: 30px;
			padding-bottom: 30px;
			
			@include media($large-up) {
				&:first-of-type {
					padding-top:0;
				}
				&::after {
					content: "";
					height: 19px;
					width: 19px;
					background: $secondary-color;
					position: absolute;
					right: -25px;
					top: 0;
					border-radius: 1000px;
				}

				h2 {
					color: $secondary-color;
				}
			}

			
		}	
	}
	.timeline-right {
		.half {
			@include media($large-up) {
			float: right;
			position: relative;
			padding-left: 20px;
				&::after {
					content: "";
					height: 19px;
					width: 19px;
					background: $primary-color;
					position: absolute;
					left: -24px;
					top: 0;
					border-radius: 1000px;
				}
			}
		}
		&::after {
			display: table;
			clear: both;
			content: "";
		}
	}
}

.module {
	
	&.intro {

		margin: 0 0 35px 0;

		@include media($large-up) {
			margin: 0 0 60px 0;
		}

		h1 {
			
			@include media($large-up) {
				margin-top:-20px;
			}
		}
	
		p {
			font-weight: 300;
			line-height: 1.6;
			margin-bottom:0;
			text-align: left;
			margin-bottom:-6px;
			@include font-size(20);
			
			@include media($large-up) {
				@include font-size(24);
				margin-bottom:0;
				line-height: 1.5;
			}

			@media only screen and (min-width: 1441px) {
				@include font-size(30);
			}

			&:last-child {
				margin-bottom:0;
			}

		}
	}

	&.announcement {
		
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		display: -webkit-flex;
		display: flex;
		height: 60px;
		line-height: 60px;

		@include media($large-up) {
			margin-top: 2.5rem;
			display: -webkit-inline-flex;
			display: inline-flex;
		}

		@media only screen and (min-width: 1300px) {
			height: 70px;
			line-height: 70px;
		}

		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		border-bottom: none;
		
		
		> span {

			-webkit-order: 0;
			-ms-flex-order: 0;
			order: 0;
			-webkit-flex: 0 1 auto;
			-ms-flex: 0 1 auto;
			flex: 0 1 auto;
			-webkit-align-self: auto;
			-ms-flex-item-align: auto;
			align-self: auto;
			font-size: 0.875rem;
			padding: 0 1.4rem;

			@media only screen and (min-width: 1300px) {
				font-size: 1rem;
				padding: 0 1.6rem;
			}

			&.date {
				flex-grow: 0;
				background-color: #EAF1F4;
				color: $black;
				font-weight: 400;
				border-right: 1px solid #fff;
			}

			&.title {
				color: $black;
				font-weight: 600;
				flex-grow: 1;
				background-color: #F2F7F9;
				line-height: 58px;
				padding-right: 1.7rem;
				
				@media only screen and (min-width: 1300px) {
					line-height: 67px;
				}
				
			}
		}

	}

	a.announcement {
		
		span.date {
			transition: all 0.15s ease-in-out;
			position: relative;
			overflow: hidden;
		}

		span.date span {
			z-index:1;
			position: relative;
		}

		span.date:after {
			content:"";
			width: 100%;
			height: 100%;
			position: absolute;
			left:0;
			z-index: 0;
			background-color: $link-active-color;
			transform:translateX(-101%);
			transition: all 0.15s ease-in-out;
		}

		span.title span {
			
			color: $secondary-color;
			border-bottom: 1px solid $link-underline-color;
			transition: all 0.15s ease-in-out;
		}
		
		@include media($large-up) {
			&:hover {

				span.date {
					color: #fff;
				}

				span.date:after {
					transform:translateX(0);
				}

				span.title span {
					color: $link-active-color;
					border-color: transparent;
				}
			}
		}
	}

	&.hero-image {

		width: calc(100% + 60px);
		margin-left: -30px;
		position: relative;
		margin-top: 0.5rem;

		@include media($large-up) {
			margin-top: 0;
			width: 100%;
			margin-left: 0;
		}

		.image-container {
			width: 100%;
			margin: 0 0 35px 0;
			@include media($large-up) {
				margin: 0 0 60px 0;
			}

			img {
				margin: 0;
				width: 100%;
			}
		}
		
	}

	&.half-split {
		width: 100%;
		position: relative;
		color: white;
		//margin: 30px 0;
		overflow: hidden;
		z-index: 1;
		
		@include media($large-up) {
			padding: 90px 0;
			//margin: 45px 0;
		}

		.container {
			
			padding:0;

			@include media($large-up) {
				padding: 0 30px;
			}
		}
		
		@include media($large-up) {
			.pattern {
				content:"";
				width: 325px;
				height: 133px;
				// background: url('../images/ocy-pattern-split-long.svg') 0 0 no-repeat;
				// background-size: 325px 133px;
				position: absolute;
				bottom: -45px;
				left: -90px;
				// transform: rotate(-145deg);
				opacity: 1;
				z-index: 1;
				display: none;
			}
		}

		.half {

			padding: 60px 30px;

			@include media($large-up) {
				padding:0;
			}
			
			&:first-child {
				
				background-color: $secondary-color;


				@include media($large-up) {
					padding-right: 45px;
					background-color: transparent;
				}

				@media only screen and (min-width: 1300px) {
					padding-right: 60px;
				}
			}

			&:last-child {
				
				background-color: $secondary-color-alt;

				@include media($large-up) {
					padding-left: 45px;
					background-color: transparent;
				}

				@media only screen and (min-width: 1300px) {
					padding-left: 60px;
				}
			}

		}

		* {
			z-index: 1;
			position: relative;
		}

		@include media($large-up) {

			&:before, &:after {
				content:"";
				width: 50%;
				height: 100%;
				display: block;
				position: absolute;
				top:0;
				z-index:0;
			}

			&:before {
				left:0;
				background-color: $secondary-color;
			}

			&:after {
				left: 50%;
				background-color: $secondary-color-alt;
			}

			&.ir {
				&:before {
					background-color: $secondary-color-alt;
				}

				&:after {
					background-color: $secondary-color;
				}
			}

		}

		h2.heading {
			color: inherit;
			text-align: center;
			margin-bottom: 35px;

			@include media($large-up) {
				text-align: left;
				margin-top:8px;
				margin-bottom: 40px;
			}
		}
	}

	&.linkedin { 
		width: 100%;
		position: relative;
		margin-top: 30px;
		overflow: hidden;
		background-color: $gray;
		margin-bottom: -122px;
		padding: 30px 0 75px;
		
		@include media($large-up) {
			padding: 90px 0 187px 0;
			margin-top: 45px;
			margin-bottom: -137px;
		}

		.container {
			padding: 30px;
			@include media($large-up) {
				padding: 0 30px;
			}
		}

		.linkedin-post {

			float: left;
			clear: both;
			padding-bottom: 40px;

			@include media($large-up) {
				margin-top: 5px;
				padding-bottom:0;
				float: none;
			}
			
			.post-image {
				width: 20%;
				float: left;

				@include media($large-up) {
					width: 30%;
				}
			}

			.post-content {
				width: 80%;
				float: left;
				@include font-size(14);
				padding-left: 15px;

				@include media($large-up) {
					width: 70%;
				}

				p {
					font-size: inherit;
					padding:0;
					margin-top:-6px;
					margin-bottom: 12px;
				}

				a {
					border-bottom: none;
					font-size: inherit;
				}

				.post-footer {
					list-style: none;
					padding:0;
					margin:0;
					height: 16px;
					font-size: inherit;

					li {
						display: inline-block;
						height: 16px;
						margin-right: 11px;

						&:last-child {
							margin-right:0;
						}

						a {
							display: block;
							height: 16px;
							line-height: 16px;
							color: #565656;
							font-weight: 400;

							&:before {
								content:"";
								width: 16px;
								height: 16px;
								display: inline-block;
								background-size: 16px 16px;
								background-repeat: no-repeat;
								background-position: center center;
								position: relative;
								top:2px;
							}

							&.post-date {
								&:before {
									background-image: url('../images/linkedin-glyph-symbol.svg');
									margin-right:6px;
								}
							}

							&.post-like {
								&:before {
									background-image: url('../images/linkedin-glyph-like.svg');
									margin-right:3px;
								}
							}

							&.post-share {
								&:before {
									background-image: url('../images/linkedin-glyph-share.svg');
									margin-right:5px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.contact-list {
		width: 100%;
		border-top: 1px dashed $border-color-dashed;
		padding-top: 32px;
		margin-top: 45px;

		h3 {
			margin-bottom:0.9rem !important;
			font-family: "Open Sans Condensed", sans-serif !important;
			text-transform: uppercase !important;
			font-size: 18px !important;
		}

		p {
			line-height: 2 !important;
			font-weight: 300 !important;
			font-size: 1rem !important;
		}
	}
}

.ir-graphs-container {
	//background-color: $gray;
	//margin: 30px 0;

	@include media($large-up) {
		//margin: 45px 0;
		//padding: 60px 0;
	}
}

.ir-graphs-ir {
	color: white !important;

	.highcharts-title {
		display: none;
	}

	#graph-share, #graph-daily-dividend-history {
		height: 300px;
	}

	text {
		color: white !important;
		fill: white !important;
		outline: none;

		tspan {
			outline: none !important;
		}
	}

	g {
		.highcharts-button, rect {
			background: none;
			color: white;
			fill: none;
			outline: none !important;
		}
	}

	.highcharts-series {
		path:first-of-type {
			stroke: rgba(#fff, .8) !important;
		}
	}

	.highcharts-tooltip {

		path {
			stroke: none !important;
			fill: white !important;
		}
		
		text {
			
			tspan {
				font-family: $font-sans-serif;
				color: $primary-color !important;
				font-size: 11px !important;
				fill: $primary-color !important;

				@include media($large-up) {
					font-size: 12px !important;
				}
			}
		}
	}

	.highcharts-scrollbar {
		rect:first-child {
			fill: rgba(white, .05);
			stroke: rgba(white, .1);
		}

		rect:nth-child(2) {
			stroke: rgba(white, .1);
		}
	}

	.highcharts-range-selector-buttons,
	.highcharts-input-group {
		text {
			color: rgba(#fff, .6) !important;
			fill: rgba(#fff, .6) !important;
		}

		g {
			.highcharts-button, rect {
				color: rgba(#fff, .6) !important;
			}
		}
	}

	.highcharts-input-group {
		g:nth-child(2), g:nth-child(4) {
			rect {
				stroke: rgba(#fff, .15);
			}
		}
	}
}

.ir-graphs {
	
	color: $black;
	position: relative;
	@include media($large-up) {
		&:before {
			content:"";
			position: absolute;
			top:0;
			left: 50%;
			height: 100%;
			width: 1px;
			margin-left:-1px;
			border-right: 1px dashed $border-color-dashed;
		}
	}

	&.dividend {
		
		&:before {
			display: none !important;
		}

		.half-split:first-of-type {
			&:before {
			content:"";
			position: absolute;
			top:0;
			left: 50%;
			height: 100%;
			width: 1px;
			margin-left:-1px;
			border-right: 1px dashed $border-color-dashed;
		}
		}
		
	}


	.half-split {
	
		color: inherit;
		padding:0;

		&:first-child {
			margin-bottom:0;

			.half {
				padding-bottom: 45px;
			}

			.container {
				@include media($large-up) {
					&:after {
						content:"";
						width: 100%;
						height: 1px;
						border-bottom: 1px dashed $border-color-dashed;
					}
				}
			}

			.border {
				width: 100%;
				height: 1px;

			}

		}
		&:last-child {
			margin-top:0;

			.half {
				padding-top: 45px;
			}
		}

		&:before, &:after {
			background-color: transparent;
		}

		.full {
			padding-top: 45px;
			padding-left:0 !important;
			padding-right:0 !important;
		}

		.half, .full {
		
		h2.heading {
			color: $primary-color;
		}
		

		&:first-child {
			
			@include media($large-up) {
				padding-right: 45px;
			}
		}

		&:last-child {
			@include media($large-up) {
				padding-left: 45px;
			}
		}
		
		background-color: transparent !important;

		&:nth-child(even) {
			background-color: rgba(0,0,0,0.01) !important;
		}

		@include media($large-up) {
			background-color: transparent !important;
			&:nth-child(even) {
				background-color: transparent !important;
			}
		}
		
		.dividend-yield {

			.dividend-yield-box {
				.dividend-yield-number {
					color: $secondary-color;
				}
			}

			.dividend-details {

				ul {

					border-color: $border-color;


					li {
						border-color: $border-color;

						.share-number {
							color: $secondary-color;
						}
					}
				}
			}

		}

		#graph-dividend-history {

			.highcharts-container {

				.highcharts-series {
					path:first-of-type {
						stroke: $secondary-color !important;
					}
				}
				
				.highcharts-markers {
					path {
						fill: $gray !important;
						stroke: $secondary-color !important;
					}
				}

				.highcharts-data-labels g text {
					fill: $black !important;
				}

				.highcharts-axis-labels text {
					fill: $black !important;
				}

				.highcharts-grid path {
					stroke: $border-color !important;
				}

				.highcharts-axis path {
					stroke: $border-color !important;
				}

			}
		}

		.highcharts-title {
			display: none;
		}

		.highcharts-series {
			path:first-of-type {
				stroke: $secondary-color !important;
			}
		}

		.highcharts-tooltip {

			path {
				stroke: none !important;
				fill: white !important;
			}
			
			text {
				
				tspan {
					font-family: $font-sans-serif;
					color: $primary-color !important;
					font-size: 11px !important;
					fill: $primary-color !important;

					@include media($large-up) {
						font-size: 12px !important;
					}
				}
			}
		}

		#graph-share, #graph-daily-dividend-history {
			height: 300px;
		}

	}
	}
	
}

/* donut chart - charter backlog */
.responsive-chart-container {
	
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	margin-bottom: 30px;
	margin-top: -45px;

	@include media($medium-up) {
		margin-top:0;
		margin-bottom: 45px;
	}

	.donut-chart-title {
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 0;
		display: flex;
		text-align: center;
		align-items: center;
		text-align: center;

		span {
			text-align: center;
			display: inline-block;
			font-weight: 300;
			width: 100%;
			margin: 0 auto;
			line-height: 1.3;
			padding-top: 28px;
			@include font-size(13);

			@include media($medium-up) {
				@include font-size(16);
			}

			@include media($large-up) {
				@include font-size(18);
			}
		}
	}

	#donut-chart-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		z-index: 1;
	}
}

/* backlog table */
table.backlog-table {
	
	width: 100%;
	margin-bottom: 45px;

	tbody {
		tr {

			@media only screen and (max-width: 899px) {
				border-bottom: 1px solid $border-color;
			}

			td {

				@media only screen and (max-width: 899px) {
					border: none;

					&:first-child {
						padding-bottom:0;
					}

					&:last-child {
						padding-top: 7px;
						padding-bottom: 18px;
					}
				}

				.graph {
					width: 100%;
					min-width: 250px;
					height: 16px;
					position: relative;
					border-radius: 1000px;
					overflow: hidden;

					&:after {
						content:"";
						width: 100%;
						height: 100%;
						position: absolute;
						top:0;
						left:0;
						border: 1px solid $border-color;
						z-index: 0;
						background-color: white;
						border-radius: 1000px;
					}
					
					&:before {
						content: attr(data-percentage);
						width: 100%;
						height: 100%;
						position: absolute;
						top:0;
						left:0;
						z-index: 2;
						color: $black;
						@include font-size(12);
						text-align: center;
						font-weight: 600;
					}

					.graph-bar {
						height: 100%;
						display: block;
						position: absolute;
						top:0;
						left:0;
						z-index: 1;
					}

				}

			}
		}
	}
}

.backlog-smallprint {
	font-weight: 300;
	@include font-size(14);

	@include media($large-up) {
		@include font-size(16);
	}
}

/* contact us */

.contact-us-ir {
	width: 100%;

	.third.column-box {
		width: 100%;
		padding:0;
		margin:0;
		float: none !important;
		border-top: 1px dashed $border-color-dashed;
		padding: 53px 0 15px 0;

		h2 {
			margin-bottom:20px;
		}
	}
}

.map-container {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	margin-top: 30px;

	
	@include media($large-up) {
		padding-right: 30px;
		padding-bottom: 75%;
		margin-top:75px;
	}

	.contact-map {
		position: absolute;
		width: 100%;
		height: 100%;
		/*top:0;*/

		&:after {
			content:"";
			width: 100%;
			height: 100%;
			position: absolute;
			top:0;
			left:0;
			pointer-events: none;
			border: 1px solid rgba(0,0,0,0.1);
		}
	}
	#contact-map-malta {
		position: absolute;
		width: 100%;
		height: 100%;
		/*top:0;*/

		&:after {
			content:"";
			width: 100%;
			height: 100%;
			position: absolute;
			top:0;
			left:0;
			pointer-events: none;
			border: 1px solid rgba(0,0,0,0.1);
		}
	}
}

.map-popup {
	@include font-size(14);
	font-weight: 400;
	line-height: 1.7;
	padding: 0 10px;
}








