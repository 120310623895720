// SETTINGS
$border-box-sizing: true !default;

// Media queries
$medium-up: min-width 641px;
$large-up: min-width 1024px;
$medium-down: max-width 1023px;

// Colors
$primary-color: #001f3d;
$secondary-color: #004486;
$secondary-color-alt: #024B91;
$third-color: #4C5660;
$gray: #F2F7F9;
$text-gray: #7E7E7E;
$border-color: #E6E6E6;
$border-color-dashed: #E0E0E0;
$light-gray: #F3F6F8;
$white: #fff;
$black: #222222;
$link-active-color: #1276BD;
$link-active-color-light: #399FE7;
$link-underline-color: #AE9A64;

// Typography
$rem-base: 16px;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,200italic,300,300italic,400italic,600,600italic,700,700italic,900,900italic');
$font-sans-serif: "Source Sans Pro", helvetica, arial, sans-serif;

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700');
$font-condenced: "Open Sans Condensed", sans-serif;

$font-serif: $font-sans-serif;


@mixin font-size($sizeValue: 16) {
	font-size: ($sizeValue/16) + rem;
}
