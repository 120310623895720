// hero 
.hero {
	width: 100%;
	position: relative;
	padding: 25px 0 0;
	background-color: #F5F2EC;
	overflow: hidden;

	@include media($large-up) {
		padding: 45px 0 45px;
		margin-top: 15px;
	}

	.symbol {
		width: 22%;
		height: auto;
		aspect-ratio: 1/1;
		background: url(/assets/images/ocean-yield-symbol-minimal-white.svg) 0 0 no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		left: -5%;
		opacity: 1;
		z-index: 0;

		// Fallback
		@supports not (aspect-ratio: 16 / 9) {
			&::before {
			  float: left;
			  padding-top: 100%;
			  content: "";
			}
		
			&::after {
			  display: block;
			  content: "";
			  clear: both;
			}
		}
	}

	img {
		display: block;
	}
	
	.half {
		padding:0;
		height: 100%;
		position: relative;
		z-index: 1;
	}

	.container {
		@include media($medium-down) {
			padding-bottom: 0;
		}
	}

	// .container {
	// 	-webkit-order: 0;
	// 	-ms-flex-order: 0;
	// 	order: 0;
	// 	-webkit-flex: 0 1 auto;
	// 	-ms-flex: 0 1 auto;
	// 	flex: 0 1 auto;
	// 	-webkit-align-self: center;
	// 	-ms-flex-item-align: center;
	// 	align-self: center;
	// 	width: 100%;
	// }
	
	@include media($large-up) {
		.container {
			display: flex;
			align-items: center;
		}
	}

	.module {

		&.intro {
			
			margin-bottom:0;

			@include media($large-up) {
				padding-right: 60px;
			}

			@media only screen and (min-width: 1300px) {
				padding-right: 120px;
			}

			@media only screen and (min-width: 1441px) {
				padding-right: 90px;
			}
		}

		&.hero-text {

			@include media($large-up) {
				margin-top: 25px;
			}
	
			// @include media($large-up) {
			// 	margin:0;
			// 	position: absolute;
			// 	top:0;
			// 	width: 100%;
			// 	height: 100%;
			// 	padding-top:0;
			// 	padding-bottom:0;
			// 	display: -ms-flexbox;
			// 	display: -webkit-flex;
			// 	display: flex;
			// 	-webkit-flex-direction: row;
			// 	-ms-flex-direction: row;
			// 	flex-direction: row;
			// 	-webkit-flex-wrap: nowrap;
			// 	-ms-flex-wrap: nowrap;
			// 	flex-wrap: nowrap;
			// 	-webkit-justify-content: flex-start;
			// 	-ms-flex-pack: start;
			// 	justify-content: flex-start;
			// 	-webkit-align-content: stretch;
			// 	-ms-flex-line-pack: stretch;
			// 	align-content: stretch;
			// 	-webkit-align-items: flex-start;
			// 	-ms-flex-align: start;
			// 	align-items: flex-start;
			// }
		}
	}

	+ section.container {
		margin-top: 2rem;

		@include media($large-up) {
			margin-top: 5rem;
		}

	}

}

section.container.intro-small {
	padding-bottom: 0;

	@include media($large-up) {
		padding-bottom: 1.5rem;
	}
}